@use '@angular/material' as mat;
@import 'variables';
@import 'material-components-web/material-components-web';
@import '@angular/cdk/overlay-prebuilt.css';
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.

/**
* Generated theme by Material Theme Generator
* https://materialtheme.arcsine.dev
*/

/*
* Generated theme by Material Theme Generator * https: //materialtheme.arcsine.dev
*/
@import '@angular/material/theming';
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.

// Fonts
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');

$mdc-theme-primary: #401d5a;
$mdc-theme-secondary: #65758f;
$mdc-theme-background: #f6f5f7;

@font-face {
  font-family: 'Roboto';
  font-weight: bold;
  src: url('assets/fonts/roboto/Roboto-Regular.ttf') format('truetype');
}

$fontConfig: (
  display-4: mat.define-typography-level(112px, 112px, 300, 'Roboto', -0.0134em),
  display-3: mat.define-typography-level(56px, 56px, 400, 'Roboto', -0.0089em),
  display-2: mat.define-typography-level(45px, 48px, 400, 'Roboto', 0em),
  display-1: mat.define-typography-level(34px, 40px, 400, 'Roboto', 0.0074em),
  headline: mat.define-typography-level(24px, 32px, 400, 'Roboto', 0em),
  title: mat.define-typography-level(20px, 32px, 500, 'Roboto', 0.0075em),
  subheading-2: mat.define-typography-level(16px, 28px, 400, 'Roboto', 0.0094em),
  subheading-1: mat.define-typography-level(15px, 24px, 500, 'Roboto', 0.0067em),
  body-2: mat.define-typography-level(14px, 24px, 500, 'Roboto', 0.0179em),
  body-1: mat.define-typography-level(14px, 20px, 400, 'Roboto', 0.0179em),
  button: mat.define-typography-level(14px, 14px, 500, 'Roboto', 0.0893em),
  caption: mat.define-typography-level(12px, 20px, 400, 'Roboto', 0.0333em),
  input: mat.define-typography-level(inherit, 1.125, 400, 'Roboto', 1.5px),
  h1: mat.define-typography-level(112px, 112px, 700, 'Roboto', -0.0134em),
  h2: mat.define-typography-level(56px, 56px, 600, 'Roboto', -0.0089em),
  h3: mat.define-typography-level(45px, 48px, 500, 'Roboto', 0em),
  h4: mat.define-typography-level(34px, 40px, 500, 'Roboto', 0.0074em),
  h5: mat.define-typography-level(20px, 32px, 500, 'Roboto', 0.0067em),
);

// Foreground Elements

// Light Theme Text
$dark-text: #212121;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

mat.$light-theme-foreground-palette: (
  base: black,
  divider: $dark-dividers,
  dividers: $dark-dividers,
  disabled: $dark-disabled-text,
  disabled-button: rgba($dark-text, 0.26),
  disabled-text: $dark-disabled-text,
  elevation: black,
  secondary-text: $dark-accent-text,
  hint-text: $dark-disabled-text,
  accent-text: $dark-accent-text,
  icon: $dark-accent-text,
  icons: $dark-accent-text,
  text: $dark-primary-text,
  slider-min: $dark-primary-text,
  slider-off: rgba($dark-text, 0.26),
  slider-off-active: $dark-disabled-text,
);

// Dark Theme text
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

mat.$dark-theme-foreground-palette: (
  base: $light-text,
  divider: $light-dividers,
  dividers: $light-dividers,
  disabled: $light-disabled-text,
  disabled-button: rgba($light-text, 0.3),
  disabled-text: $light-disabled-text,
  elevation: black,
  hint-text: $light-disabled-text,
  secondary-text: $light-accent-text,
  accent-text: $light-accent-text,
  icon: $light-text,
  icons: $light-text,
  text: $light-text,
  slider-min: $light-text,
  slider-off: rgba($light-text, 0.3),
  slider-off-active: rgba($light-text, 0.3),
);

// Background config
// Light bg
$light-background: #fafafa;
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-tooltip: lighten(#2c2c2c, 20%);
$dark-bg-alpha-4: rgba(#2c2c2c, 0.04);
$dark-bg-alpha-12: rgba(#2c2c2c, 0.12);

mat.$light-theme-background-palette: (
  background: $light-background,
  status-bar: $light-bg-darker-20,
  app-bar: $light-bg-darker-5,
  hover: $dark-bg-alpha-4,
  card: $light-bg-lighter-5,
  dialog: $light-bg-lighter-5,
  tooltip: $dark-bg-tooltip,
  disabled-button: $dark-bg-alpha-12,
  raised-button: $light-bg-lighter-5,
  focused-button: $dark-focused,
  selected-button: $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle: $light-bg-darker-10,
  unselected-chip: $light-bg-darker-10,
  disabled-list-option: $light-bg-darker-10,
);

// Dark bg
$dark-background: #2c2c2c;
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba(#fafafa, 0.04);
$light-bg-alpha-12: rgba(#fafafa, 0.12);

// Background palette for dark themes.
mat.$dark-theme-background-palette: (
  background: $dark-background,
  status-bar: $dark-bg-lighter-20,
  app-bar: $dark-bg-lighter-5,
  hover: $light-bg-alpha-4,
  card: $dark-bg-lighter-5,
  dialog: $dark-bg-lighter-5,
  tooltip: $dark-bg-lighter-20,
  disabled-button: $light-bg-alpha-12,
  raised-button: $dark-bg-lighter-5,
  focused-button: $light-focused,
  selected-button: $dark-bg-lighter-20,
  selected-disabled-button: $dark-bg-lighter-30,
  disabled-button-toggle: $dark-bg-lighter-10,
  unselected-chip: $dark-bg-lighter-20,
  disabled-list-option: $dark-bg-lighter-10,
);

// Compute font config
@include mat.core($fontConfig);

// Theme Config
body {
  --primary-color: #d2cadb;
  --primary-lighter-color: #f6f5f7;
  --primary-darker-color: #401d5a;
  --text-primary-color: #{$light-primary-text};
  --text-primary-lighter-color: #{$dark-primary-text};
  --text-primary-darker-color: #{$light-primary-text};
  font-family: Roboto;
}

$mat-primary: (
  main: #d2cadb,
  lighter: #f6f5f7,
  darker: #401d5a,
  200: #f6f5f7,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$theme-primary: mat.define-palette($mat-primary, main, lighter, darker);

body {
  --accent-color: #c9ccd1;
  --accent-lighter-color: #e6e9ed;
  --accent-darker-color: #65758f;
  --text-accent-color: #{$dark-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$dark-primary-text};
}

$mat-accent: (
  main: #c9ccd1,
  lighter: #e6e9ed,
  darker: #65758f,
  200: #e6e9ed,
  // For slide toggle,
  contrast:
    (
      main: $dark-primary-text,
      lighter: $dark-primary-text,
      darker: $dark-primary-text,
    ),
);
$theme-accent: mat.define-palette($mat-accent, main, lighter, darker);

body {
  --warn-color: #ff0000;
  --warn-lighter-color: #ffb3b3;
  --warn-darker-color: #ff0000;
  --text-warn-color: #{$light-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$light-primary-text};
}

$mat-warn: (
  main: #ff0000,
  lighter: #ffb3b3,
  darker: #ff0000,
  200: #ff0000,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$theme-warn: mat.define-palette($mat-warn, main, lighter, darker);

$theme: mat.define-light-theme($theme-primary, $theme-accent, $theme-warn);
$altTheme: mat.define-dark-theme($theme-primary, $theme-accent, $theme-warn);

// Theme Init
@include mat.all-component-themes($theme);

.theme-alternate {
  @include mat.all-component-themes($altTheme);
}

/* standardize tables */
.mat-row:nth-child(even) {
  background-color: $alternating-row-color;
}

.mat-row {
  min-height: $row-height !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.mat-header-row {
  padding-top: 0 !important;
  margin-top: 0 !important;
  min-height: $row-header-height !important;

  .mat-header-cell {
    color: black !important;
    font-size: $row-header-text-size !important;
    font-weight: $row-header-text-weight !important;
    margin-right: 5px;
  }
}

.mat-cell {
  margin-right: 5px;
  word-break: break-word;
}

/* standardize dialogs */
.mat-dialog-container {
  background-color: $secondary-accent-color !important;
  padding-top: 0 !important;

  .mat-dialog-title {
    padding: 5px !important;
    margin: 5px !important;
    padding-left: 0 !important;
    margin-left: 0 !important;
    font-size: $dialog-header-text-size;
    font-weight: $dialog-header-text-weight;
  }

  .mat-dialog-content {
    background-color: $secondary-color-light !important;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: $primary-text-color;
    font-weight: $primary-text-weight;
  }

  .mat-dialog-actions {
    height: 60px;
    background-color: $secondary-accent-color !important;
    display: flex;
    flex-direction: row-reverse;
    flex-flow: row-reverse nowrap;
  }

  .mat-dialog-actions button {
    height: 40px;
    min-width: 100px !important;
    margin: 0 10px 0 10px;
    background-color: $secondary-color;
    color: $primary-color-text;
    border: 1px solid $secondary-color-light !important;
  }
}

.employer-search-dialog-container mat-dialog-container {
  padding: 0px!important;
}

/* standardize "create button" layout */
.create-button {
  min-width: 100%;
  width: 100%;
  margin: 5px 0 5px 0;
  padding: 0;
  display: flex;
  flex: auto;
  flex-direction: column;
  flex-flow: column wrap;
  align-content: flex-start;
  justify-content: left;

  .left {
    flex: 1;
    align-content: flex-start;
    justify-content: left;

    .button1 > button {
      margin-left: 0 !important;
    }
  }
}

.filter-actions {
  padding: 10px;
  box-sizing: border-box;
  box-shadow: 2px 0px 1px 1px #ddd;
}

.clear-filters-button {
  width: 50%;
}

/* standardize "no result" layout */
.noresult {
  min-width: 100%;
  width: 100%;
  margin: 50px 0 0 0;
  padding: 20px 10px 10px 10px;
  display: flex;
  flex: auto;
  flex-direction: column;
  flex-flow: column wrap;
  align-content: center;
  justify-content: center;

  .center {
    flex: 1;
    align-content: center;
    justify-content: center;
  }
}

.noresult-small {
  min-width: 100%;
  width: 100%;
  margin: 10px 0 0 0;
  padding: 10px 10px 20px 10px;
  display: flex;
  flex: auto;
  flex-direction: column;
  flex-flow: column wrap;
  align-content: center;
  justify-content: center;

  .center {
    flex: 1;
    align-content: center;
    justify-content: center;
  }
}

/* remove outline on buttons */
button:focus,
button:active:focus,
button.active:focus {
  outline: none !important;
  outline-style: none !important;
}

button.button--secondary {
  color: $primary-color-text;
  background-color: $secondary-color;
  height: 36px;
}

// Specific component overrides, pieces that are not in line with the general theming

// Handle buttons appropriately, with respect to line-height
.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
  padding: 0 1.15em;
  margin: 0 0.65em;
  min-width: 3em;
  line-height: 36.4px;
}

.mat-standard-chip {
  padding: 0.5em 0.85em;
  min-height: 2.5em;
}

// standardize material icon size
.material-icons {
  font-size: 24px;
  font-family: 'Material Icons', 'Material Icons';

  .mat-badge-content {
    font-family: 'Roboto';
  }
}

// shrink labels
ng-deep .mat-form-field-flex > .mat-form-field-infix {
  padding: 0.4em 0px !important;
}

.mat-form-field-appearance-outline .mat-form-field-flex > .mat-form-field-infix {
  padding: 0.5em 0px !important;
}

// shrink labels
// ng-deep .mat-form-field-label-wrapper {
//   top: -1.5em;
// }

// shrink labels
ng-deep .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  transform: translateY(-1.34375em) scale(1) !important;
  width: 133.33333%;
}

// shrink labels
.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  -ms-transform: translateY(-1.34375em) scale(1) !important;
  transform: translateY(-1.34375em) scale(1) !important;
  width: 133.33333333%;
}

// shrink labels
.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: $primary-text-color;
}

// drop the caret on select fields
.mat-form-field-appearance-standard.mat-form-field-has-label
  .mat-select:not(.mat-select-empty)
  .mat-select-arrow-wrapper {
  transform: scale(1.5) translateY(0%) !important;
}

.mat-form-field-label {
  color: #737373;
}

.mat-select-arrow-wrapper {
  transform: scale(1.5);
}

// enforce centered text on table headers
.mat-sort-header-container {
  justify-content: start;
}

// customize select panel options to break word.
.mat-select-panel mat-option.mat-option {
  margin: 1rem 0;
  overflow: visible;
  line-height: initial;
  word-wrap: break-word;
  white-space: pre-wrap;
}

// body style over-rides to ensure containers flex and fit
html {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  overflow: hidden;
  min-height: 100%;
  margin: 0;
  padding: 0;
  font-weight: 400;
  background-color: #fff;
  height: 100%;
}

* {
  box-sizing: border-box;
}

label > span.ng-star-inserted {
  color: red;
}

input {
  margin-top: 5px;
}

input.mat-input-element {
  margin-top: 5px;
}

// Utility SCSS Styles
.flex-row {
  display: flex;
  width: 100%;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-between {
  justify-content: space-between;
}

.flex-around {
  justify-content: space-around;
}

.flex-evenly {
  display: flex;

  justify-content: space-evenly;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.flex-end {
  justify-content: flex-end;
}

.flex-wrap {
  flex-wrap: wrap;
}
.flex-reverse {
  display: flex;

  flex-direction: row-reverse;
}

.overlay {
  position: absolute;
  z-index: 1002;
  background-color: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: 100%;
}

.is-small-column {
  width: 80px;
  max-width: 80px;
}

.is-linkable {
  color: blue !important;
  text-decoration: underline !important;
}

.is-linkable:hover {
  color: blue !important;
  text-decoration: underline !important;
  cursor: pointer;
}

.is-centered {
  text-align: center;
}

.spacer {
  flex: 1 1 auto;
}

.required-asterisk {
  color: red;
}

/* Designing for scroll-bar */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f6f5f7;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #401d5a;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #65758f;
}

.p-1 {
  padding: 15px;
}

.cf2-multiple-entries-card {
  .list-block,
  .edit-block {
    border: 1px solid #999;
    padding-bottom: 10px;
  }

  .list-block {
    margin-bottom: 16px;
  }

  .edit-block {
    padding-top: 24px;

    .action-buttons button {
      margin-left: 8px;
    }
  }

  .action-buttons {
    display: flex;
    flex-direction: row;
    padding: 8px;
    position: relative;
    float: right;
    align-self: flex-end;
  }

  .loading {
    text-align: center;
    font-size: 24px;
    padding: 16px;
  }
}

.spinner-wrapper {
  left: 50%;
  top: 50%;
  margin-top: -50%;
  display: block;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('/assets/images/spinner.gif');
  width: 100px;
  height: 100px;
  margin: 0 auto;
  position: fixed;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  filter: alpha(opacity=50);
  -ms-opacity: 0.5;
  opacity: 0.5;
  z-index: 10000;
}

.noOfRecords {
  font-size: smaller;
  float: right;
  margin-right: 5px;
}

.display-flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.flex-4 {
  flex: 4;
}

.flex-5 {
  flex: 5;
}

.flex-6 {
  flex: 6;
}

[class*='flex-'] {
  padding-left: 10px;
  padding-right: 10px;
}

[class*='flex-']:first-child {
  padding-left: 0;
}

[class*='flex-']:nth-last-child(2) {
  padding-right: 0;
}

.level-1 {
  background-color: #eee;
  .mat-flat-button,
  .mat-raised-button,
  .mat-fab,
  .mat-mini-fab {
    background-color: #eee;
  }
  .level-2 {
    background-color: #fff;
    .mat-flat-button,
    .mat-raised-button, 
    .mat-fab,
    .mat-mini-fab {
      background-color: #fff;
    }
  }
}

.button-primary {
  height: 40px;
  min-width: 100px !important;
  margin: 0 10px 0 10px;
  background-color: $secondary-color;
  color: $primary-color-text;
  border: 1px solid $secondary-color-light !important;
}

.button-alternate {
  height: 40px;
  min-width: 100px !important;
  margin: 0 10px 0 10px;
  background-color: $secondary-accent-color;
  color: $primary-text-color;
  border: 1px solid $secondary-color-light !important;
}

.grid-1 {
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;
}

.grid-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
}

.grid-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 8px;
}

.grid-4 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 8px;
}

.grid-6 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 8px;
}

@media (max-width: 1200px) {
  .grid-1
  .grid-2,
  .grid-3,
  .grid-4,
  .grid-6 {
    display: grid;
    grid-template-columns: 1fr;
    gap: 8px;
  }
}

.red-bold {
  color: red;
  font-weight: 500;
}

.width-100 {
  width: 100%;
}

.badge-spacing > .mat-badge-content {
  //right: -30px !important;
  top: 0 !important;
}

::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color:    #707071;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
 color:    #707071;
 opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
 color:    #707071;
 opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
 color:    #707071;
}
::-ms-input-placeholder { /* Microsoft Edge */
 color:    #707071;
}

::placeholder { /* Most modern browsers support this now. */
 color:    #707071;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax($min-form-field-width, 1fr));
  column-gap: $grid-column-gap;
  padding: 0px;
}

.no-padding{
  padding: 0px;
}

.mat-paginator-page-size-label, .mat-paginator-range-label{
  color: #000c !important;
}

nav ul li .mat-badge-content {
  color: white;
  background-color: #1c3557;
}