/* Palette generated by Material Palette - materialpalette.com/teal/blue */

$production-primary-color: var(--production-primary-color);
$production-accent-color: var(--production-accent-color);
$secondary-color: var(--secondary-color);
$alternating-row-color: var(--alternating-row-color);
$panel-header-color: var(--panel-header-color);

:root{
  --production-primary-color: #1c3557;
  --secondary-color: #65758f;
  --production-accent-color: #65758f;
  --alternating-row-color: #e6e9ed;
  --panel-header-color: #65758f;
}

/* colors */
$primary-color: #401d5a;
$accent-color: #d2cadb;
$primary-color-light: #f6f5f7;
$primary-color-dark: #00796b;

// Primary red shades
// Not in use
// $red--production-primary-color: #931438;
// $red--production-accent-color: #f1cddf;

// primary blue shades
//$production-primary-color: #1c3557; // rgb(28,53,87)   /// CF261 - Dynamic Themes
//$production-accent-color: #c9d6e9;   /// CF261 - Dynamic Themes

$training-primary-color: #995f7b;

//$secondary-color: #65758f;   /// CF261 - Dynamic Themes
$secondary-accent-color: #c9ccd1;
$secondary-color-light: #e6e9ed;

$divider-color: #401d5a; /* primary color */

$cf-hover: rgba(#757575, 0.04);

/* warning message / error */
$warning-color: #ff0000;
$warning-color-background: #ffadad;

/* hyperlinks */
$link-color: #0000ee;

/* button */
$disabled-color: #f6f5f7; /* secondary color light */
$disabled-text-color: #c9ccd1; /* secondary accent color */

/* borders */
$border-color: #212121;

/* text colors and weight */
$primary-color-text: #ffffff; /* light on dark */
$readonly-richtext-color: #fafafa;
$primary-text-color: #212121;
$secondary-text-color: #757575;
$primary-text-weight: 400;

/* dialog */
$dialog-header-text-size: 16px;
$dialog-header-text-weight: 600;

/* page header */
$header-height: 60px;
$header-text-size: 20px;
$header-text-weight: 700;
$subheader-text-size: 16px;
$subheader-text-weight: 600;
$header-label-color: #000000; /* dark */

/* filter and menu */
$filter-width: 250px;
$menu-width: 250px;
$menu-padding-top: 50px;
$menu-text-size: 16px;
$menu-text-weight: 500;
$menu-child-text-size: 14px;
$menu-child-text-weight: 400;

/* grid */
//$alternating-row-color: #e6e9ed; /* secondary color light*/   /// CF261 - Dynamic Themes
$row-height: 34px;
$row-header-height: 34px;
$row-header-text-size: 14px;
$row-header-text-weight: 500;
$row-header-text-size-small: 12px;
$table-text-size-small: 12px;

/* panels */
//$panel-header-color: #65758f; /* secondary color */   /// CF261 - Dynamic Themes
$panel-height: 40px;
$panel-height-small: 20px;
$panel-header-text-size: 16px;
$panel-header-text-weight: 500;
$panel-header-text-color: #ffffff; /* light on dark */

$audit-text-size: 12px;

/* search control (header) */
$search-text-size: 14px;
$search-border-color: #401d5a; /* primary color */

/* expansion panel / card */
$large-card-width: 540px;

/* input controls */
$large-input-width: 200px;
$large-input-height: 78px;
$large-input-height-disabled: 58px;
$small-input-width: 160px;

$input-field-padding: 0 5px 0 5px;
$input-field-margin: 5px 0 0 20px;
$input-field-margin-no-left: 5px 0 0 0;
$input-text-weight: 400;
$input-field-top: -20px;
$input-text-size: 16px;

$input-field-padding-small: 0 5px 0 5px;
$input-field-margin-small: 5px 0 0 10px;

$label-text-size: 14px;
$label-text-weight: 500;

$min-form-field-width: 240px;
$grid-column-gap: 16px;
